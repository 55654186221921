import ZmailChangePassword from '@/layouts/Zmail/components/ZmailChangePassword.vue';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  methods: {
    // zmailChangePassword: function (instance) {
    //   const that = this;
    //   return new Promise(() => {
    //     const selfName = 'zmailChangePassword';
    //     this.$modals.open({
    //       name: selfName,
    //       size: 'small',
    //       component: ZmailChangePassword,
    //       closeOnBackdrop: false,
    //       props: {
    //         name: instance.name,
    //       },
    //       on: {
    //         change: data => {
    //           instance = data;
    //           // console.log(data);
    //           Vue.set(this.modal.footer.confirm.props, 'disabled', false);
    //         },
    //         notready: () => {
    //           Vue.set(this.modal.footer.confirm.props, 'disabled', true);
    //         },
    //       },
    //       onOpen: inst => (this.modal = inst),
    //       onClose: () => (this.modal = null),
    //       onDismiss: () => (this.modal = null),
    //       footer: {
    //         confirm: {
    //           props: { title: this.$t('sure.confirm') },
    //           on: {
    //             click: () => {
    //               Vue.set(this.modal, 'footer', {
    //                 confirm: {
    //                   props: { loading: true },
    //                 },
    //               });
    //               const params = {
    //                 zmailChangePassword: {
    //                   adminPass: instance,
    //                 },
    //               };
    //               this.sendServerAction(JSON.stringify(params))
    //                 .then(data => {
    //                   console.log(data);
    //                   if (data === 202) {
    //                     that.$modals.close();
    //                     this.showResModal('Пароль успешно изменен');
    //                   }
    //                 })
    //                 .catch(e => {
    //                   that.$modals.close();
    //                   if (e.response.status === 501) {
    //                     this.showError('Не установлен guestAgent');
    //                   } else this.showError(e);
    //                 });
    //             },
    //           },
    //         },
    //         cancel: {
    //           on: {
    //             click: () => {
    //               that.$modals.close();
    //             },
    //           },
    //         },
    //       },
    //     });
    //   });
    // },
    // zmailChangePassword() {
    //   let modal = null;
    //   this.$modals.open({
    //     name: 'ReinstallOs',
    //     component: BaseLoader,
    //     closable: false,
    //     onOpen: inst => (modal = inst),
    //     onClose: () => (modal = null),
    //   });
    //   const params = {
    //     func: 'vmrogue_reinstall_os',
    //     elid: this.tariff.id,
    //     // out: 'none',
    //     show_metadata: 'on',
    //   };
    //   this.$store
    //     .dispatch('moduleVPS/reinstallOs', params)
    //     .then(data => {
    //       // console.log(data);
    //       let self = null;
    //       let hasChanges = false;
    //       this.$modals.close();
    //       this.$modals.open({
    //         name: 'SettingsModal',
    //         size: 'big',
    //         component: ReinstallOS,
    //         props: data,
    //         on: {
    //           ready: () => {
    //             self.footer.confirm.props.disabled = false;
    //           },
    //           change: val => {
    //             // console.log(val);
    //             hasChanges = val;
    //           },
    //           submit: data => {
    //             const payload = { data };
    //             if (props.user) payload.id = props.user.id;
    //             Vue.set(self.props, 'formLoading', true);
    //             this.updateUser(payload)
    //               .then(() => this.$modals.close())
    //               .catch(e => {
    //                 // console.log(e);
    //                 this.showError(e);
    //                 Vue.set(self.props, 'formLoading', false);
    //                 Vue.set(self.props, 'doSubmit', false);
    //                 if (e.type === 'value' && e.object) {
    //                   Vue.set(self.props, 'errors', {
    //                     [e.object]: { msg: e.msg, value: data[e.object] },
    //                   });
    //                 }
    //               });
    //           },
    //         },
    //         onOpen: instance => (self = instance),
    //         onClose: () => (self = null),
    //         footer: {
    //           confirm: {
    //             props: { title: this.$t('submit'), disabled: true },
    //             on: {
    //               click: () => {
    //                 {
    //                   Vue.set(self.footer.confirm.props, 'loading', true);
    //                   let res = '';
    //                   let time = Date.now();
    //                   this.$store
    //                     .dispatch('moduleVPS/sendReinstallOs', {
    //                       ...hasChanges,
    //                     })
    //                     .then(data => {
    //                       // console.log(data);
    //                       res = 'reinstall';
    //                       //
    //                     })
    //                     .catch(e => {
    //                       res = 'fail';
    //                       // console.log(e);
    //                       this.showError(e);
    //                     })
    //                     .finally(() => {
    //                       Vue.set(self.footer.confirm.props, 'loading', false);
    //                       // console.log(res);
    //                       // this.$modals.close();
    //                       // this.showSuccessModal(res);
    //                       const delay = Date.now() - time < 1000 ? 1000 : 0;
    //                       setTimeout(() => {
    //                         self.component = null;
    //                         self.closable = true;
    //                         self.text = this.$t(`modal.sure.${res}`);
    //                         self.footer = {
    //                           centered: true,
    //                           confirm: {
    //                             props: { title: this.$t('modal.sure.ok') },
    //                             on: { click: () => this.$modals.close() },
    //                           },
    //                         };
    //                       }, delay);
    //                     });
    //                 }
    //               },
    //             },
    //           },
    //           // cancel: {
    //           //   on: { click: () => this.$modals.dismiss() },
    //           // },
    //         },
    //       });
    //     })
    //     .catch(e => {
    //       Vue.set(modal, 'component', null);
    //       Vue.set(modal, 'closable', true);
    //       this.showError(e);
    //     })
    //     .finally(() => this.$modals.close);
    // },
    // sendServerAction(params) {
    //   return this.$store.dispatch('moduleStack/setServerAction', {
    //     id: this.tariff.id,
    //     params,
    //   });
    // },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
