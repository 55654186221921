import { render, staticRenderFns } from "./ZmailChangePassword.vue?vue&type=template&id=63e7d6de&scoped=true"
import script from "./ZmailChangePassword.vue?vue&type=script&lang=js"
export * from "./ZmailChangePassword.vue?vue&type=script&lang=js"
import style0 from "./ZmailChangePassword.vue?vue&type=style&index=0&id=63e7d6de&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e7d6de",
  null
  
)

/* custom blocks */
import block0 from "./ZmailChangePassword.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports