<template>
  <div class="allowed-user-settings-form">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" />
      <div v-else class="allowed-user-settings-form__content">
        <div class="l-col">
          <label class="standart-title">{{
            $t(`fields.label`, {
              itemName: model.name,
            })
          }}</label>
        </div>
        <div class="l-flex-1_sm-1-gutter-4">
          <div class="l-col">
            <div
              v-for="item in newFields"
              :key="item.key"
              class="allowed-user-settings-form__field"
            >
              <base-input
                :key="item.name"
                :ref="item.name"
                v-model="item.value"
                :tooltip="item.tooltip || null"
                :type="item.type"
                :name="item.name"
                :required="item.required"
                :custom-error-messages="
                  customValidationMsgs[item.name] ? customValidationMsgs[item.name] : null
                "
                autocomplete="off"
                use-reactive-validation
                :pattern="item.name === 'confirm' ? formData.passwd : null"
                @change="onChange(item, $event.target.value)"
                @input="onInput"
              >
                <template v-if="item.name === 'passwd'" #icon>
                  <plain-button icon="key" color="dark" tabindex="-1" @click="generatePass(item)" />
                </template>
                <template #fieldAfter>
                  <plain-button
                    :icon="item.type === 'text' ? 'eye-closed' : 'eye-open'"
                    color="dark"
                    tabindex="-1"
                    @click="showPassword(item)"
                  />
                </template>
                {{ $t(`fields.${item.name}.label`) }}
              </base-input>
            </div>
          </div>
          <div
            v-if="fields.info.type === 'msg' && fields.info && fields.info.label"
            class="l-col allowed-user-settings-form__info"
          >
            <label class="l-col">{{ fields.info.label }}</label>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
/* eslint-disable */
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { isArray } from 'lodash';
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import generatePassword from '@/layouts/Zmail/mixins/zmailGeneratePassword.js';
import { format } from 'date-fns';
import showErrorModal from '@/mixins/showErrorModal';
import mixin from '@/layouts/Profile/mixins';
export default {
  name: 'ZmailChangePassword',
  components: {
    BaseSelect,
    BaseInput,
  },
  mixins: [setFocus, showErrorModal, generatePassword, mixin],
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isReady: false,
      isLoading: !!this.user,
      initialBackup: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('fields.confirm.patternMismatch') },
      },
      formData: {},
      newFields: [
        {
          name: this.fields.passwd.name,
          type: this.fields.passwd.type,
          invalid: false,
          value: '',
          tooltip: this.$t('fields.passwd.tooltip'),
          hint: this.fields.passwd.hint,
          label: this.fields.passwd.label,
          notprop: this.fields.passwd.notprop,
        },
        {
          name: this.fields.confirm.name,
          type: this.fields.confirm.type,
          required: true,
          invalid: false,
          value: '',
          tooltip: this.$t('fields.passwd.tooltip'),
          hint: this.fields.confirm.hint,
          label: this.fields.confirm.label,
          notprop: this.fields.confirm.notprop,
        },
      ],
    };
  },
  computed: {},
  watch: {
    hasChanges(val) {
      this.$emit('form-data-change', val);
    },
    newFields(val) {
      this.setInitialFormData(val);
    },
  },
  mounted() {
    if (this.newFields.length) this.setInitialFormData(this.newFields);
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    setInitialFormData(list) {
      this.initialBackup = '';
      this.formData = list.reduce((acc, item) => {
        acc[item.name] = item.value;
        this.initialBackup += item.value;
        return acc;
      }, {});
      this.isReady = true;
    },
    onInput() {
      this.ifGenerate = false;
    },
    showPassword(item) {
      if (item.type === 'password') {
        item.type = 'text';
      } else {
        item.type = 'password';
      }
    },
    isArray(item) {
      return isArray(item);
    },
    onChange(key, val) {
      this.formData[key.name] = val;
      if (this.ifGenerate) {
        this.formData.confirm = this.formData.passwd;
      }
      // this.isError();
      this.$emit('change', this.formData);
      if (this.formData.confirm && this.formData.passwd && this.formData.passwd === this.formData.confirm) this.$emit('ready');
      else this.$emit('notready');
    },
    clear() {
      this.formData = {};
      this.initialBackup = '';
      this.setInitialFormData(this.newFields);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "fields": {
      "label" : "Смена пароля - {itemName}",
      "passwd": {
        "label": "Новый пароль",
        "tooltip": "Сгенерировать надежный пароль"
      },
      "confirm": {
        "label": "Подтверждение пароля",
        "tooltip": "Подтверждение пароля",
        "patternMismatch": "Пароли не совпадают"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-settings-form {
  &__avatar-upload {
    margin: 0 auto 1.5rem;
  }
  &__info{
    margin-top: 1.25rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }

    & + &--checkbox {
      +breakpoint(sm-and-up) {
        margin-top: 4rem;
      }
    }
  }
  &__footer {
    +breakpoint(sm-and-up) {
      margin: 1.5rem auto 0;
      flexy(flex-end, center);
    }
  }
  &__btn {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
    }
  }
}
</style>
